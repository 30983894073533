import {
  FAIL_FETCHING_API,
  FAIL_FETCHING_LOGIN_STATE,
  FAIL_FETCHING_USER,
  FETCH_USER,
  LOGIN,
  LOGOUT
} from 'redux/constants/Auth';

const initialState = {
  isPrepared: false,
  isLoggedIn: false,
  user: {
    project: ''
  },
  isFetching: false,
  error: undefined,
  jwt: '',
  pathname: null
};

function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FAIL_FETCHING_LOGIN_STATE:
      return { ...initialState, isPrepared: true, pathname: payload.pathname };
    case FETCH_USER:
      return { ...state, isFetching: true, error: undefined };
    case FAIL_FETCHING_USER:
      return { ...state, isFetching: false, error: payload };
    case LOGIN:
      return {
        ...state,
        isPrepared: true,
        isLoggedIn: true,
        user: payload.user || state.user,
        isFetching: false,
        error: undefined,
        jwt: payload.jwt,
        pathname: payload.pathname
      };
    case LOGOUT:
      return { ...initialState, isPrepared: true };
    case FAIL_FETCHING_API:
      return { ...initialState, error: payload.err };
    default:
      return state;
  }
}

export default authReducer;
