import { DeviceState, TDevice, TWorkflow } from 'types/device';
import {
  CREATE_DEVICE,
  RETRIEVE_DEVICES,
  UPDATE_DEVICE,
  DELETE_DEVICE,
  RETRIEVE_DEVICES_FAIL,
  RETRIEVE_DEVICES_SUCCESS,
  CREATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_SUCCESS,
  SEARCH_DEVICE,
  SEARCH_DEVICE_SUCCESS,
  CREATE_DEVICE_BULK,
  RETRIEVE_DEVICE_SUCCESS,
  DEVICE_BULK_UPLOAD_STATUS_SUCCESS,
  DEVICE_BULK_UPLOAD_STATUS,
  GET_DEVICE_STATS,
  GET_DEVICE_STATS_SUCCESS,
  GET_DEVICE_STATS_FAILURE,
  GET_DEVICE_TIMELINE,
  GET_DEVICE_TIMELINE_FAILURE,
  GET_DEVICE_TIMELINE_SUCCESS,
  GET_TEE_PROPERTIES,
  GET_TEE_PROPERTIES_SUCCESS,
  GET_TEE_PROPERTIES_FAILURE,
  GET_TRANSACTION_LOGS,
  GET_TRANSACTION_LOGS_FAILURE,
  GET_TRANSACTION_LOGS_SUCCESS,
  ELIGIBILITY_SEARCH_DEVICE,
  ELIGIBILITY_SEARCH_DEVICE_SUCCESS
} from '../constants/Device';

const initialState: DeviceState = {
  loading: false,
  error: false,
  total: 0,
  message: '',
  devices: [],
  selectedDevice: {} as TDevice,
  workFlow: {} as TWorkflow,
  bulkUploadStatus: [],
  stats: {},
  teeProperties: [],
  transactionLogs: [],
  transactionLogsLoading: false
};

function deviceReducer(state = initialState, action) {
  const { type, payload } = action;
 
  console.debug(action);

  switch (type) {
    case CREATE_DEVICE:
      return { ...state, loading: true };

    case CREATE_DEVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.data.message
      };

    case RETRIEVE_DEVICES:
      return { ...state, loading: true };

    case RETRIEVE_DEVICES_SUCCESS:
      return { ...state, devices: payload.data.device, loading: false };

    case UPDATE_DEVICE:
      return {
        ...state,
        loading: true,
        devices: state.devices.map((device: any) => {
          if (device._id === payload.data._id) {
            return {
              ...device,
              ...payload.data
            };
          } else {
            return device;
          }
        })
      };

    case UPDATE_DEVICE_SUCCESS:
      return { ...state, loading: false, message: payload.message };

    case DELETE_DEVICE:
      return { ...state, loading: true };

    case RETRIEVE_DEVICES_FAIL:
      return { ...state, loading: false, error: true, message: payload.data.message || '' };

    case SEARCH_DEVICE:
      return { ...state, loading: true };

    case SEARCH_DEVICE_SUCCESS:
      return { ...state, loading: false, devices: payload.data.devices };

    case CREATE_DEVICE_BULK:
      return { ...state, loading: true };

    case RETRIEVE_DEVICE_SUCCESS:
      return { ...state, loading: false, selectedDevice: payload.data.device };

    case DEVICE_BULK_UPLOAD_STATUS:
      return { ...state, loading: true };

    case DEVICE_BULK_UPLOAD_STATUS_SUCCESS:
      return { ...state, loading: false, bulkUploadStatus: payload.data.deviceUploadedLogs };

    case GET_DEVICE_STATS:
      return { ...state, loading: true };

    case GET_DEVICE_STATS_SUCCESS:
      return { ...state, loading: false, stats: payload };

    case GET_DEVICE_STATS_FAILURE:
      return { ...state, loading: false, error: true, message: payload.data.message || '' };

    case GET_DEVICE_TIMELINE:
      return { ...state, loading: true };

    case GET_DEVICE_TIMELINE_SUCCESS:
      return {
        ...state,
        loading: false,
        workFlow: payload.data.deviceWorkFlow
      };

    case GET_DEVICE_TIMELINE_FAILURE:
      return { ...state, loading: false, error: true, message: payload.data.message || '' };

    case GET_TEE_PROPERTIES:
      return { ...state, loading: true };

    case GET_TEE_PROPERTIES_FAILURE:
      return { ...state, loading: false, error: true, message: payload.data.message || '' };

    case GET_TEE_PROPERTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        teeProperties: payload.data.deviceTeeProperties
      };
    case GET_TRANSACTION_LOGS:
      return { ...state, transactionLogsLoading: true };

    case GET_TRANSACTION_LOGS_FAILURE:
      return {
        ...state,
        transactionLogsLoading: false,
        error: true,
        message: payload.data || '',
        transactionLogs: []
      };

    case GET_TRANSACTION_LOGS_SUCCESS:
      return {
        ...state,
        transactionLogsLoading: false,
        transactionLogs: payload.data
      };

    case ELIGIBILITY_SEARCH_DEVICE:
      return { ...state, loading: true };

    case ELIGIBILITY_SEARCH_DEVICE_SUCCESS:
      return { ...state, loading: false, devices: payload.data.devices };

    default:
      return state;
  }
}

export default deviceReducer;
