import { combineReducers } from 'redux';
import Auth from './auth';
import Theme from './Theme';
import Device from './Device';
import Category from './Category';
import Crypto from './Crypto';
import Eligibility from './Eligibility';
import Provisioning from './Provisioning';
import userGroup from './userGroups'
import User from './Users'
import Permission from './permission';
import Certificate from './Certificate';

const reducers = combineReducers({
  auth: Auth,
  theme: Theme,
  device: Device,
  users : User,
  permission : Permission,
  usergroup : userGroup,
  category: Category,
  crypto: Crypto,
  eligibility: Eligibility,
  provisioning: Provisioning,
  certificate: Certificate
});

export default reducers;
