import { take, call, put } from "redux-saga/effects";
import {
  createUser,
  createUserSucces,
  retrieveUsers,
  retrieveUsersSucces,
  retrieveUsersFailure,
  deleteUser,
  deleteUserSuccess,
  updateUser,
  updateUserSuccess,
  searchUsers,
  searchUsersSuccess,
  deleteUserFailure,
} from "../actions/Users";
import {
  CREATE_USER_PATH,
  RETRIEVE_USERS_PATH,
  DELETE_USER_PATH,
  UPDATE_USER_PATH,
  SEARCH_USERS_PATH,
} from "../constants/Users";
import { endSession } from "../../utils/commonUtils";
import ApiInvoke from "utils/apiFetch";

// const backendEndpoints = path => `${process.env.REACT_APP_USERS_BACKEND_QA}/${path}`;

const backendEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_USERS_BACKEND_DEV}/${path}`
    : `${process.env.REACT_APP_USERS_BACKEND_QA}/${path}`;

export function* createUserApi() {
  while (true) {
    const action = yield take(`${createUser}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(CREATE_USER_PATH),
      action.payload.data,
      "POST"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(createUserSucces(payload));
      yield put(retrieveUsers({}));
      continue;
    }
    endSession(err);
  }
}

export function* retrieveUsersAPI() {
  while (true) {
    yield take(`${retrieveUsers}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(RETRIEVE_USERS_PATH),
      "",
      "GET"
    );

    console.log("payload", payload);

    if (payload && !err) {
      yield put(retrieveUsersSucces(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveUsersFailure(err));
  }
}

export function* deleteUserAPI() {
  while (true) {
    const action = yield take(`${deleteUser}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(DELETE_USER_PATH),
      action.payload.data,
      "POST"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(deleteUserSuccess(payload));
      yield put(retrieveUsers({}));
      continue;
    }
    endSession(err);
    yield put(deleteUserFailure(err));
  }
}

export function* updateUserAPI() {
  while (true) {
    const action = yield take(`${updateUser}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${UPDATE_USER_PATH}${action.payload.id}`),
      action.payload.data,
      "PUT"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(updateUserSuccess(payload));
      yield put(retrieveUsers({}));
      continue;
    }
    endSession(err);
    yield put(retrieveUsersFailure(err));
  }
}

export function* searchUserAPI() {
  while (true) {
    const action = yield take(`${searchUsers}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${SEARCH_USERS_PATH}${action.payload.path}`),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(searchUsersSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveUsersFailure(err));
  }
}
