import {
  CREATE_CRYPTO_ASSET,
  CREATE_CRYPTO_ASSET_SUCCESS,
  DELETE_CRYPTO_ASSETS,
  DELETE_CRYPTO_ASSET_SUCCESS,
  FILE_UPLOAD_INIT,
  GET_CRYPTO_STATS,
  GET_CRYPTO_STATS_FAILURE,
  GET_CRYPTO_STATS_SUCCESS,
  RETRIEVE_CRYPTO_ASSETS,
  RETRIEVE_CRYPTO_ASSETS_FAIL,
  RETRIEVE_CRYPTO_ASSETS_SUCCESS,
  RETRIEVE_CRYPTO_ASSET_SUCCESS,
  SEARCH_CRYPTO_ASSET,
  SEARCH_CRYPTO_ASSET_SUCCESS,
  UPDATE_CRYPTO_ASSET,
  UPDATE_CRYPTO_ASSET_SUCCESS,
  GET_AUDIT_LOGS,
  GET_AUDIT_LOGS_SUCCESS,
  GET_AUDIT_LOGS_FAILURE,
  RETRIEVE_SERVICE_PROVIDERS,
  RETRIEVE_SERVICE_PROVIDERS_SUCCESS,
  RETRIEVE_SERVICE_PROVIDERS_FAILURE,
  CRYPTO_BULK_UPLOAD,
  CRYPTO_BULK_UPLOAD_SUCCESS,
  CRYPTO_BULK_UPLOAD_FAILURE,
  ELIGIBILITY_SEARCH_CRYPTO_ASSET,
  ELIGIBILITY_SEARCH_CRYPTO_ASSET_SUCCESS,
} from "../constants/Crypto";

const initialState = {
  loading: false,
  error: false,
  total: 0,
  cryptoAssets: [],
  selectedCryptoAsset: {},
  serviceProviders: [],
  message: "",
  description: [],
  stats: {},
  devices: [],
  devicesLoading: false,
  uploading: false
};

function cryptoReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CRYPTO_ASSET:
      return { ...state, loading: true };

    case CREATE_CRYPTO_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        cryptoAssets: [...state.cryptoAssets, payload.data],
        message: payload.message,
      };

    case RETRIEVE_CRYPTO_ASSETS:
      return { ...state, loading: true };

    case RETRIEVE_CRYPTO_ASSETS_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        message: payload.data.message || "",
      };

    case RETRIEVE_CRYPTO_ASSETS_SUCCESS:
      return { ...state, loading: false, cryptoAssets: payload.data.crypto };

    case CRYPTO_BULK_UPLOAD:
      return { ...state, uploading: true };
    
    case CRYPTO_BULK_UPLOAD_SUCCESS:
      return { ...state, uploading: false };
    
    case CRYPTO_BULK_UPLOAD_FAILURE:
      return { ...state, uploading: false };

    case RETRIEVE_SERVICE_PROVIDERS:
      return { ...state, loading: true };

    case RETRIEVE_SERVICE_PROVIDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        message: payload.data.message || "",
      };

    case RETRIEVE_SERVICE_PROVIDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        serviceProviders: payload.data.serviceProviders,
      };

    case UPDATE_CRYPTO_ASSET:
      return {
        ...state,
        loading: true,
        cryptoAssets: state.cryptoAssets.map((crypto: any) => {
          if (crypto._id === payload.data._id) {
            return {
              ...crypto,
              ...payload.data,
            };
          } else {
            return crypto;
          }
        }),
      };

    case UPDATE_CRYPTO_ASSET_SUCCESS:
      return { ...state, loading: false, message: payload.message };

    case DELETE_CRYPTO_ASSETS:
      return {
        ...state,
        loading: true,
        cryptoAssets: state.cryptoAssets.map((crypto: any, index) => {
          if (crypto._id === payload.id) {
            return {
              ...state.cryptoAssets.splice(index, 1),
            };
          } else {
            return crypto;
          }
        }),
      };

    case DELETE_CRYPTO_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.data.message,
        description: payload.data.description,
      };

    case SEARCH_CRYPTO_ASSET:
      return { ...state, loading: true };

    case SEARCH_CRYPTO_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        cryptoAssets: payload.data.cryptos,
      };

    case RETRIEVE_CRYPTO_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCryptoAsset: payload.data.crypto,
      };

    case FILE_UPLOAD_INIT:
      return { ...state, loading: true };

    case GET_CRYPTO_STATS:
      return { ...state, loading: true };

    case GET_CRYPTO_STATS_SUCCESS:
      return { ...state, loading: false, stats: payload };

    case GET_CRYPTO_STATS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        message: payload.data.message || "",
      };

    case GET_AUDIT_LOGS:
      return { ...state, auditLogsLoading: true };

    case GET_AUDIT_LOGS_FAILURE:
      return {
        ...state,
        auditLogsLoading: false,
        error: true,
        message: payload.data.message || "",
        auditLogs: [],
      };

    case GET_AUDIT_LOGS_SUCCESS:
      return {
        ...state,
        auditLogsLoading: false,
        auditLogs: payload.data.auditLogs,
      };

    case ELIGIBILITY_SEARCH_CRYPTO_ASSET:
      return { ...state, loading: true };

    case ELIGIBILITY_SEARCH_CRYPTO_ASSET_SUCCESS:
      return {
        ...state,
        loading: false,
        cryptoAssets: payload.data.cryptos,
      };

    default:
      return state;
  }
}

export default cryptoReducer;
