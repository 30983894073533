import { take, call, put } from "redux-saga/effects";
// import * as AWSCognito from 'amazon-cognito-identity-js';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  ICognitoUserPoolData,
} from "amazon-cognito-identity-js";
import {
  fetchLoginState,
  failFetchingLoginState,
  fetchUser,
  failFetchingUser,
  login,
  clickLogout,
  logout,
  hello,
  fetchHello,
  failFetchingApi,
} from "../actions/Auth";
import { AUTH_USER_TOKEN_KEY } from "../constants/Auth";
import ApiInvoke from "utils/apiFetch";
// import React, { useEffect, useState } from 'react';
// import Axios, { Method, AxiosInstance } from 'axios';
// import axios from 'axios';
import jwt_decode from "jwt-decode";

const poolData: ICognitoUserPoolData = {
  UserPoolId: `${process.env.REACT_APP_USER_POOL_ID}`,
  ClientId: `${process.env.REACT_APP_CLIENT_ID}`,
};

const userPool = new CognitoUserPool(poolData);

function endpoint(s) {
  return `${process.env.REACT_APP_ENDPOINT}/${s}`;
}

const getSession = (cognitoUser) =>
  new Promise((resolve, reject) => {
    cognitoUser.getSession((err, result) => {
      if (result && result.isValid()) {
        console.debug("session validity: " + result.isValid());
        cognitoUser.getUserAttributes((err, attrs) => {
          if (err) {
            resolve({ payload: null, err });
          } else {
            const jwttoken = result.getIdToken().getJwtToken();
            const decodedToken: any = jwt_decode(jwttoken);
            if (decodedToken["exp"] * 1000 < new Date().getTime()) {
              resolve({ payload: null, err });
            } else {
              const payload: any = {};
              payload.user = {};
              attrs.forEach((attr) => (payload.user[attr.Name] = attr.Value));

              payload.jwt = jwttoken;

              resolve({ payload });
            }
          }
        });
      } else {
        resolve({ payload: null, err });
      }
    });
  });

const cognitoSignIn = (params) =>
  new Promise((resolve, reject) => {
    const { email, password } = params;
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        cognitoUser.getUserAttributes(async (err, attrs: any) => {
          const payload: any = {};
          attrs.forEach((attr) => (payload[attr.Name] = attr.Value));
          payload.jwt = result.getIdToken().getJwtToken();
          resolve({ payload });

          // var token = payload.jwt;
          // var decodedToken: any = jwt_decode(token);
          // console.log("decoded  ", decodedToken);
          // console.log("User ID:  ", decodedToken.sub);
          // const coId = decodedToken.sub;
          // console.log("Done");

          // const getId = await axios.post(`https://nhfwdf5lqe.execute-api.us-east-1.amazonaws.com/qa/user/user/find`, {

          //   cognitoId: decodedToken.sub

          // })

          //   .then(function (response) {
          //     console.log(" Details  :", response);
          //     console.log(" Group ID  :", response.data.data.user.group);

          //     var groupId = response.data.data.user.group;
          //     console.log(response.status)

          //     return groupId;

          //   })
          //   .catch(function (error) {
          //     console.log(" Error : ", error);

          //     return error;
          //   });

          // console.log("User ID : ", getId);

          // const permissions = await axios.get(`https://nhfwdf5lqe.execute-api.us-east-1.amazonaws.com/qa/user/usergroup/${getId}`)
          //   .then(function (response) {
          //     console.log("booonm");
          //     console.log("group Permissions ", response.data.data.group.permissions);

          //     var permissions = response.data.data.group.permissions;

          //     return permissions;
          //   })
          //   .catch(function (error) {

          //     console.log(error);

          //     return error;
          //   })

          //   console.log("permissions  ", permissions  );

          //   const output = JSON.stringify(permissions);

          //   localStorage.setItem("userPermissions",output);
          //   return output;
        });
      },
      onFailure: (err) => {
        resolve({ payload: null, err });
      },
    });
  });

const getAccessToken = (cognitoUser) =>
  new Promise((resolve, reject) => {
    cognitoUser.getSession((err, result) => {
      if (result && result.isValid()) {
        const token = result.getAccessToken().getJwtToken();
        resolve({ token });
      } else {
        resolve({ token: null, err });
      }
    });
  });

const globalSignOut = (cognitoUser) =>
  new Promise((resolve, reject) => {
    cognitoUser.globalSignOut({
      onSuccess: (result) => {
        resolve({ result });
      },
      onFailure: (err) => {
        resolve({ result: null, err });
      },
    });
  });

export function* handleFetchLoginState() {
  while (true) {
    const action: any = yield take(`${fetchLoginState}`);

    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      const { payload, err } = yield call(getSession, cognitoUser);

      if (payload && !err) {
        yield put(login(Object.assign({}, payload, action.payload)));
        continue;
      } else {
        yield put(failFetchingLoginState(action.payload));
        handleLogout();
        continue;
      }
    }
    yield put(failFetchingLoginState(""));
  }
}

export function* handleLogout() {
  while (true) {
    yield take(`${clickLogout}`);

    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      const { token, err } = yield call(getAccessToken, cognitoUser);

      if (token && !err) {
        const { result, err } = yield call(globalSignOut, cognitoUser);
        localStorage.removeItem(AUTH_USER_TOKEN_KEY);

        if (result && !err) {
          yield put(logout());
        }
      }
    }
  }
}

export function* handleLogin() {
  while (true) {
    const action = yield take(`${fetchUser}`);
    const { email, password } = action.payload;
    if (email && password) {
      const { payload, err } = yield call(cognitoSignIn, action.payload);

      if (!payload && err) {
        yield put(failFetchingUser(`${err.message}`));
        continue;
      }

      console.log("payload ", payload);

      localStorage.setItem(AUTH_USER_TOKEN_KEY, payload.jwt);
      yield put(login(payload));
      continue;
    }

    yield put(failFetchingUser("Please set email and password"));
  }
}

export function* handleApi() {
  while (true) {
    const action = yield take(`${hello}`);

    const { payload, err } = yield call(
      ApiInvoke,
      endpoint(action.payload.path),
      "",
      "POST"
    );

    if (payload && !err) {
      yield put(fetchHello(payload));
      continue;
    }
    yield put(failFetchingApi(err));
  }
}
