import {
  CREATE_ELIGIBILITY_RULE,
  CREATE_ELIGIBILITY_RULE_SUCCESS,
  DELETE_ELIGIBILITY_RULE,
  DELETE_ELIGIBILITY_RULES,
  DELETE_ELIGIBILITY_RULE_SUCCESS,
  RETRIEVE_ELIGIBILITY_RULES,
  RETRIEVE_ELIGIBILITY_RULES_FAIL,
  RETRIEVE_ELIGIBILITY_RULES_SUCCESS,
  SEARCH_ELIGIBILITY_RULE,
  SEARCH_ELIGIBILITY_RULE_SUCCESS,
  UPDATE_ELIGIBILITY_RULE,
  UPDATE_ELIGIBILITY_RULE_SUCCESS,
  RETRIEVE_ELIGIBILITY_RULE,
  RETRIEVE_ELIGIBILITY_RULE_SUCCESS,
  DELETE_ELIGIBILITY_RULE_FAIL,
  GET_ELIGIBILITY_STATS,
  GET_ELIGIBILITY_STATS_SUCCESS,
  GET_ELIGIBILITY_STATS_FAILURE,
  CHANGE_ELIGIBILITY_STATUS,
  CHANGE_ELIGIBILITY_STATUS_SUCCESS,
  CHANGE_ELIGIBILITY_STATUS_FAILURE
} from '../constants/Eligibility';

import { createAction } from 'redux-act';

// Eligibility
export const createEligibility = createAction<any | undefined>(CREATE_ELIGIBILITY_RULE);
export const createEligibilitySuccess = createAction<any | undefined>(CREATE_ELIGIBILITY_RULE_SUCCESS);
export const updateEligibility = createAction<any | undefined>(UPDATE_ELIGIBILITY_RULE);
export const updateEligibilitySuccess = createAction<any | undefined>(UPDATE_ELIGIBILITY_RULE_SUCCESS);
export const deleteEligibility = createAction<any | undefined>(DELETE_ELIGIBILITY_RULE);
export const deleteEligibilityRules = createAction<any | undefined>(DELETE_ELIGIBILITY_RULES);
export const deleteEligibilitySuccess = createAction<any | undefined>(DELETE_ELIGIBILITY_RULE_SUCCESS);
export const deleteEligibilityFailure = createAction<any | undefined>(DELETE_ELIGIBILITY_RULE_FAIL);

export const retrieveEligibility = createAction<any | undefined>(RETRIEVE_ELIGIBILITY_RULES);
export const retrieveEligibilitySuccess = createAction<any | undefined>(RETRIEVE_ELIGIBILITY_RULES_SUCCESS);
export const retrieveEligibilityRule = createAction<any | undefined>(RETRIEVE_ELIGIBILITY_RULE);
export const retrieveEligibilityRulesSuccess = createAction<any | undefined>(RETRIEVE_ELIGIBILITY_RULE_SUCCESS);
export const retrieveEligibilityFailure = createAction<any | undefined>(RETRIEVE_ELIGIBILITY_RULES_FAIL);

export const searchEligibility = createAction<any | undefined>(SEARCH_ELIGIBILITY_RULE);
export const searchEligibilitySuccess = createAction<any | undefined>(SEARCH_ELIGIBILITY_RULE_SUCCESS);

export const getEligibilityStats = createAction<any | undefined>(GET_ELIGIBILITY_STATS);
export const getEligibilityStatsSuccess = createAction<any | undefined>(GET_ELIGIBILITY_STATS_SUCCESS);
export const getEligibilityStatsFailure = createAction<any | undefined>(GET_ELIGIBILITY_STATS_FAILURE);

export const changeEligibilityStatus = createAction<any | undefined>(CHANGE_ELIGIBILITY_STATUS);
export const changeEligibilityStatusSuccess = createAction<any | undefined>(CHANGE_ELIGIBILITY_STATUS_SUCCESS);
export const changeEligibilityStatusFailure = createAction<any | undefined>(CHANGE_ELIGIBILITY_STATUS_FAILURE);