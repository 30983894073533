import {
  CREATE_DEVICE,
  CREATE_DEVICE_SUCCESS,
  DELETE_DEVICE,
  DELETE_DEVICE_SUCCESS,
  RETRIEVE_DEVICE,
  RETRIEVE_DEVICES,
  RETRIEVE_DEVICES_FAIL,
  RETRIEVE_DEVICES_SUCCESS,
  UPDATE_DEVICE,
  UPDATE_DEVICE_SUCCESS,
  SEARCH_DEVICE_SUCCESS,
  SEARCH_DEVICE,
  CREATE_DEVICE_BULK,
  RETRIEVE_DEVICE_SUCCESS,
  DEVICE_BULK_UPLOAD,
  DEVICE_BULK_UPLOAD_STATUS,
  DEVICE_BULK_UPLOAD_STATUS_SUCCESS,
  GET_DEVICE_STATS,
  GET_DEVICE_STATS_SUCCESS,
  GET_DEVICE_STATS_FAILURE,
  GET_DEVICE_TIMELINE,
  GET_DEVICE_TIMELINE_SUCCESS,
  GET_DEVICE_TIMELINE_FAILURE,
  GET_DEVICE_UPLOAD_SAMPLE_CSV,
  GET_DEVICE_UPLOAD_SAMPLE_CSV_SUCCESS,
  GET_DEVICE_UPLOAD_SAMPLE_CSV_FAILURE,
  GET_TEE_PROPERTIES,
  GET_TEE_PROPERTIES_SUCCESS,
  GET_TEE_PROPERTIES_FAILURE,
  GET_TRANSACTION_LOGS,
  GET_TRANSACTION_LOGS_SUCCESS,
  GET_TRANSACTION_LOGS_FAILURE,
  ELIGIBILITY_SEARCH_DEVICE,
  ELIGIBILITY_SEARCH_DEVICE_SUCCESS
} from '../constants/Device';

import { createAction } from 'redux-act';

// Device
export const createDevice = createAction<any | undefined>(CREATE_DEVICE);
export const createDeviceSuccess = createAction<any | undefined>(CREATE_DEVICE_SUCCESS);
export const deleteDevice = createAction<any | undefined>(DELETE_DEVICE);
export const deleteDeviceSuccess = createAction<any | undefined>(DELETE_DEVICE_SUCCESS);
export const retrieveDevice = createAction<any | undefined>(RETRIEVE_DEVICE);
export const retrieveDeviceSuccess = createAction<any | undefined>(RETRIEVE_DEVICE_SUCCESS);
export const retrieveDevices = createAction<any | undefined>(RETRIEVE_DEVICES);
export const retrieveDevicesSuccess = createAction<any | undefined>(RETRIEVE_DEVICES_SUCCESS);
export const retrieveDevicesFailure = createAction<any | undefined>(RETRIEVE_DEVICES_FAIL);
export const updateDevice = createAction<any | undefined>(UPDATE_DEVICE);
export const updateDeviceSuccess = createAction<any | undefined>(UPDATE_DEVICE_SUCCESS);
export const searchDevice = createAction<any | undefined>(SEARCH_DEVICE);
export const searchDeviceSuccess = createAction<any | undefined>(SEARCH_DEVICE_SUCCESS);
export const createDeviceBulk = createAction<any | undefined>(CREATE_DEVICE_BULK);

export const deviceBulkUpload = createAction<any | undefined>(DEVICE_BULK_UPLOAD);
export const deviceBulkUploadStatus = createAction<any | undefined>(DEVICE_BULK_UPLOAD_STATUS);
export const deviceBulkUploadStatusSuccess = createAction<any | undefined>(DEVICE_BULK_UPLOAD_STATUS_SUCCESS);

export const getDeviceUploadSampleCSV = createAction<any | undefined>(GET_DEVICE_UPLOAD_SAMPLE_CSV);
export const getDeviceUploadSampleCSVSuccess = createAction<any | undefined>(GET_DEVICE_UPLOAD_SAMPLE_CSV_SUCCESS);
export const getDeviceUploadSampleCSVFailure = createAction<any | undefined>(GET_DEVICE_UPLOAD_SAMPLE_CSV_FAILURE);

export const getDeviceStats = createAction<any | undefined>(GET_DEVICE_STATS);
export const getDeviceStatsSuccess = createAction<any | undefined>(GET_DEVICE_STATS_SUCCESS);
export const getDeviceStatsFailure = createAction<any | undefined>(GET_DEVICE_STATS_FAILURE);

export const getDeviceTimeline = createAction<any | undefined>(GET_DEVICE_TIMELINE);
export const getDeviceTimelineSuccess = createAction<any | undefined>(GET_DEVICE_TIMELINE_SUCCESS);
export const getDeviceTimelineFailure = createAction<any | undefined>(GET_DEVICE_TIMELINE_FAILURE);

export const getTEEProperties = createAction<any | undefined>(GET_TEE_PROPERTIES);
export const getTEEPropertiesSuccess = createAction<any | undefined>(GET_TEE_PROPERTIES_SUCCESS);
export const getTEEPropertiesFailure = createAction<any | undefined>(GET_TEE_PROPERTIES_FAILURE);

export const getTransactionLogs = createAction<any | undefined>(GET_TRANSACTION_LOGS);
export const getTransactionLogsSuccess = createAction<any | undefined>(GET_TRANSACTION_LOGS_SUCCESS);
export const getTransactionLogsFailure = createAction<any | undefined>(GET_TRANSACTION_LOGS_FAILURE);

export const eligibilitySearchDevice = createAction<any | undefined>(ELIGIBILITY_SEARCH_DEVICE);
export const eligibilitySearchDeviceSuccess = createAction<any | undefined>(ELIGIBILITY_SEARCH_DEVICE_SUCCESS);