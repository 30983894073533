import { UsersState, TTransaction } from 'types/user';
import {
  // GET_USERS,
  // GET_USERS_FAILURE,
  // GET_USERS_SUCCESS,
  SEARCH_USERS,
  SEARCH_USERS_SUCCESS,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  RETRIEVE_USERS,
  RETRIEVE_USERS_SUCCESS,
  RETRIEVE_USERS_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
} from '../constants/Users';

const initialState: UsersState = {
  loading: false,
  error: false,
  total: 0,
  message: '',
  user: [],
  usersData: [],
  selectedTransaction: {} as TTransaction
};

function usersReducer(state = initialState, action) {
  const { type, payload } = action;
  console.debug(action);

  switch (type) {

    case CREATE_USER:
      return {
        ...state,
        loading: true,
        message: payload.data.message
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.data.message
      };

    case SEARCH_USERS:
      return { ...state, loading: true };

    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        rules: payload.data.rules,
        total: payload.data.total
      };

    case RETRIEVE_USERS:

      return { ...state, loading: true };

    case RETRIEVE_USERS_SUCCESS:
      return { ...state, loading: false, users: payload.data.users };


    case RETRIEVE_USERS_FAILURE:
      return { ...state, loading: false, error: true, message: payload.data.message || '' };

    case DELETE_USER:
      return {
        ...state,
        loading: true,
        user: state.usersData.map((user: any, index) => {
          if (user._id === payload.id) {
            return {
              ...state.usersData.splice(index, 1)
            };
          } else {
            return user;
          }
        })
      };

    case DELETE_USER_SUCCESS:
      return { ...state, loading: false, users: payload.data.users };

    case UPDATE_USER:
      return {
        ...state,
        loading: true,
        devices: state.usersData.map((user: any) => {

          if (user._id === payload.data._id) {
            return {
              ...user,
              ...payload.data
            };
          } else {
            return user;
          }
        })
      };

    case UPDATE_USER_SUCCESS:
      return { ...state, loading: false, message: payload.message };
    default:
      return state;
  }
}

export default usersReducer;
