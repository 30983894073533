import {
    CREATE_CERTIFICATE,
    CREATE_CERTIFICATE_SUCCESS,
    RETRIEVE_CERTIFICATES,
    RETRIEVE_CERTIFICATES_SUCCESS,
    RETRIEVE_CERTIFICATES_FAILIURE,
    RETRIEVE_CERTIFICATE,
    RETRIEVE_CERTIFICATE_SUCCESS,
    RETRIEVE_CERTIFICATE_FAILIURE,
    RETRIEVE_CERTIFICATE_STATUS,
    RETRIEVE_CERTIFICATE_STATUS_SUCCESS,
    RETRIEVE_CERTIFICATE_STATUS_FAILIURE,
    SEARCH_CERTIFICATE_SUCCESS,
    SEARCH_CERTIFICATE,
} from '../constants/Certificate';
import { createAction } from 'redux-act';

export const createCertificate = createAction<any | undefined>(CREATE_CERTIFICATE);
export const createCertificateSuccess = createAction<any | undefined>(CREATE_CERTIFICATE_SUCCESS);

export const retriveCertificates = createAction<any | undefined>(RETRIEVE_CERTIFICATES);
export const retriveCertificatesSuccess = createAction<any | undefined>(RETRIEVE_CERTIFICATES_SUCCESS);
export const retriveCertificatesFailiure = createAction<any | undefined>(RETRIEVE_CERTIFICATES_FAILIURE);

export const retriveCertificate = createAction<any | undefined>(RETRIEVE_CERTIFICATE);
export const retriveCertificateSuccess = createAction<any | undefined>(RETRIEVE_CERTIFICATE_SUCCESS);
export const retriveCertificateFailiure = createAction<any | undefined>(RETRIEVE_CERTIFICATE_FAILIURE);

export const retriveCertificateStatus = createAction<any | undefined>(RETRIEVE_CERTIFICATE_STATUS);
export const retriveCertificateStatusSuccess = createAction<any | undefined>(RETRIEVE_CERTIFICATE_STATUS_SUCCESS);
export const retriveCertificateStatusFailiure = createAction<any | undefined>(RETRIEVE_CERTIFICATE_STATUS_FAILIURE);

export const searchCertificate = createAction<any | undefined>(SEARCH_CERTIFICATE);
export const searchCertificateSuccess = createAction<any | undefined>(SEARCH_CERTIFICATE_SUCCESS);
