import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootSaga from '../sagas';
import rootReducers from '../reducers';

export default function configureStore(preloadedState: any) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, thunk];

  const store = createStore(rootReducers, preloadedState, applyMiddleware(...middlewares));

  sagaMiddleware
    .run(rootSaga)
    .toPromise()
    .catch((e: any) => {
      console.debug({ message: e.message, source: 'sagaError', stacktrace: e.sagaStack });
      store.dispatch({ type: 'ERROR', payload: e });
    });

  return store;
}
