import { CategoryState } from 'types/device';
import {
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY,
  RETRIEVE_CATEGORIES,
  RETRIEVE_CATEGORIES_FAIL,
  RETRIEVE_CATEGORIES_SUCCESS,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS
} from '../constants/Category';

const initialState: CategoryState = {
  loading: false,
  error: false,
  total: 0,
  categories: [],
  message: ''
};

function categoryReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CATEGORY:
      return { ...state, loading: true };

    case CREATE_CATEGORY_SUCCESS:
      return { ...state, loading: false, categories: [...state.categories, payload.data], message: payload.message };

    case RETRIEVE_CATEGORIES:
      return { ...state, loading: true };

    case RETRIEVE_CATEGORIES_FAIL:
      return { ...state, loading: false, error: true, message: payload.data.message || '' };

    case RETRIEVE_CATEGORIES_SUCCESS:
      return { ...state, loading: false, categories: payload.data.category };

    case UPDATE_CATEGORY:
      return {
        ...state,
        loading: true,
        categories: state.categories.map((category: any) => {
          if (category._id === payload.data._id) {
            return {
              ...category,
              ...payload.data
            };
          } else {
            return category;
          }
        })
      };

    case UPDATE_CATEGORY_SUCCESS:
      return { ...state, loading: false, message: payload.message };

    case DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.map((category: any, index) => {
          if (category._id === payload.id) {
            return {
              ...state.categories.splice(index, 1)
            };
          } else {
            return category;
          }
        })
      };

    default:
      return state;
  }
}

export default categoryReducer;
