import { ProvisioningState, TTransaction } from 'types/provisioning';
import {
  GET_PROVISIONING_STATS,
  GET_PROVISIONING_STATS_FAILURE,
  GET_PROVISIONING_STATS_SUCCESS,
  SEARCH_PROVISIONING_RULE,
  SEARCH_PROVISIONING_RULE_SUCCESS
} from '../constants/Provisioning';

const initialState: ProvisioningState = {
  loading: false,
  error: false,
  total: 0,
  message: '',
  provisioningData: [],
  selectedTransaction: {} as TTransaction
};

function provisioningReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SEARCH_PROVISIONING_RULE:
      return { ...state, loading: true };

    case SEARCH_PROVISIONING_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        rules: payload.data.rules,
        total: payload.data.total
      };

    case GET_PROVISIONING_STATS:
      return { ...state, loading: false };

    case GET_PROVISIONING_STATS_SUCCESS:
      return { ...state, loading: false, stats: payload };

    case GET_PROVISIONING_STATS_FAILURE:
      return { ...state, loading: false, error: true, message: payload.data.message || '' };

    default:
      return state;
  }
}

export default provisioningReducer;
