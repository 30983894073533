import { take, call, put } from "redux-saga/effects";
import { FILE_UPLOAD_INIT_PATH } from "redux/constants/Crypto";
import {
  createDevice,
  createDeviceBulk,
  createDeviceSuccess,
  deleteDevice,
  deleteDeviceSuccess,
  deviceBulkUpload,
  deviceBulkUploadStatus,
  deviceBulkUploadStatusSuccess,
  eligibilitySearchDevice,
  eligibilitySearchDeviceSuccess,
  getDeviceStats,
  getDeviceStatsFailure,
  getDeviceStatsSuccess,
  getDeviceTimeline,
  getDeviceTimelineFailure,
  getDeviceTimelineSuccess,
  getDeviceUploadSampleCSV,
  getDeviceUploadSampleCSVFailure,
  getDeviceUploadSampleCSVSuccess,
  getTEEProperties,
  getTEEPropertiesFailure,
  getTEEPropertiesSuccess,
  getTransactionLogs,
  getTransactionLogsFailure,
  getTransactionLogsSuccess,
  retrieveDevice,
  retrieveDevices,
  retrieveDevicesFailure,
  retrieveDevicesSuccess,
  retrieveDeviceSuccess,
  searchDevice,
  searchDeviceSuccess,
  updateDevice,
  updateDeviceSuccess,
} from "../actions/Device";

import {
  ACTION_SAMPLE_CSV_NAME,
  CREATE_DEVICE_BULK_PATH,
  CREATE_DEVICE_PATH,
  CREATE_SAMPLE_CSV_NAME,
  DELETE_DEVICE_PATH,
  DEVICE_BULK_UPLOAD_STATUS_PATH,
  DEVICE_UPLOAD,
  ELIGIBILITY_SEARCH_DEVICE_PATH,
  GET_DEVICE_STATS_PATH,
  GET_DEVICE_TIMELINE_PATH,
  RETRIEVE_DEVICES_PATH,
  RETRIEVE_DEVICE_PATH,
  SEARCH_DEVICE_PATH,
  TEE_PROPERTIES_PATH,
  TEE_PROPERTY,
  TRANSACTION_PATH,
  TRANSACTION_PROPERTY,
  UPDATE_DEVICE_PATH,
} from "../constants/Device";
import { DOWNLOAD_ACTION, UPLOAD_ACTION } from "redux/constants/Common";
import fileUpload from "../modules/fileUpload";
import { endSession } from "../../utils/commonUtils";
import ApiInvoke from "utils/apiFetch";

const backendEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_DEVICE_BACKEND_DEV}/${path}`
    : `${process.env.REACT_APP_DEVICE_BACKEND_QA}/${path}`;
const commonEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_COMMON_BACKEND_DEV}/${path}`
    : `${process.env.REACT_APP_COMMON_BACKEND_QA}/${path}`;
const transactionBackendEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_DEVICE_TRANSACTION_BACKEND_DEV}/${path}`
    : `${process.env.REACT_APP_DEVICE_TRANSACTION_BACKEND_QA}/${path}`;

export function* retrieveDevicesAPI() {
  while (true) {
    yield take(`${retrieveDevices}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(RETRIEVE_DEVICES_PATH),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(retrieveDevicesSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveDevicesFailure(err));
  }
}

export function* retrieveDeviceAPI() {
  while (true) {
    const action = yield take(`${retrieveDevice}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${RETRIEVE_DEVICE_PATH}${action.payload.id}`),
      "",
      "GET"
    );

    if (payload && !err) {
      // yield call(action.payload.callback, payload || err);
      yield put(retrieveDeviceSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveDevicesFailure(err));
  }
}

export function* createDeviceAPI() {
  while (true) {
    const action = yield take(`${createDevice}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(CREATE_DEVICE_PATH),
      action.payload.data,
      "POST"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(createDeviceSuccess(payload));
      yield put(retrieveDevices({}));
      continue;
    }
    endSession(err);
    yield put(retrieveDevicesFailure(err));
  }
}

export function* deleteDeviceAPI() {
  while (true) {
    const action = yield take(`${deleteDevice}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(DELETE_DEVICE_PATH),
      action.payload.data,
      "POST"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(deleteDeviceSuccess(payload));
      yield put(retrieveDevices({}));
      continue;
    }
    endSession(err);
    yield put(retrieveDevicesFailure(err));
  }
}

export function* updateDeviceAPI() {
  while (true) {
    const action = yield take(`${updateDevice}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${UPDATE_DEVICE_PATH}${action.payload.id}`),
      action.payload.data,
      "PUT"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(updateDeviceSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveDevicesFailure(err));
  }
}

export function* searchDeviceAPI() {
  while (true) {
    const action = yield take(`${searchDevice}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${SEARCH_DEVICE_PATH}${action.payload.path}`),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(searchDeviceSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveDevicesFailure(err));
  }
}

export function* createDeviceBulkAPI() {
  while (true) {
    const action = yield take(`${createDeviceBulk}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(CREATE_DEVICE_BULK_PATH),
      action.payload.data,
      "POST"
    );

    if (payload && !err) {
      yield put(createDeviceSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveDevicesFailure(err));
  }
}

export function* deviceBulkUploadInitAPI() {
  while (true) {
    const action = yield take(`${deviceBulkUpload}`);
    const fileNameIndex = action.payload.data.name.lastIndexOf(".");
    const csvFileName: string = action.payload.data.name.slice(
      0,
      fileNameIndex
    );
    const spid = csvFileName.slice(0, csvFileName.indexOf("_"));
    const fileName = `${csvFileName}_${new Date().getTime()}${spid}.csv`;

    const { payload, err } = yield call(
      ApiInvoke,
      commonEndpoint(FILE_UPLOAD_INIT_PATH),
      {
        action: UPLOAD_ACTION,
        type: DEVICE_UPLOAD,
        file: fileName,
      },
      "POST"
    );

    if (payload.status === 200) {
      const uploadURL = payload.data;
      const { res } = yield call(fileUpload, {
        url: uploadURL,
        type: "PUT",
        data: action.payload.data,
        fileName: fileName,
      });

      console.log("res::: file::", res);

      if (res.ok) {
        res["message"] = "File uploaded successfully!";
        fetch(backendEndpoint("device/bulk/upload/" + fileName)).then(
          async (response) => {
            console.log("file upload", response);
          }
        );

        yield call(action.payload.callback, res);
      }
      endSession(err);
    }
  }
}

export function* deviceBulkUploadStatusAPI() {
  while (true) {
    yield take(`${deviceBulkUploadStatus}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(DEVICE_BULK_UPLOAD_STATUS_PATH),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(deviceBulkUploadStatusSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveDevicesFailure(err));
  }
}

export function* getDeviceStatsAPI() {
  while (true) {
    yield take(`${getDeviceStats}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(GET_DEVICE_STATS_PATH),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(getDeviceStatsSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getDeviceStatsFailure(err));
  }
}

export function* getDeviceTimelineAPI() {
  while (true) {
    const action = yield take(`${getDeviceTimeline}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${GET_DEVICE_TIMELINE_PATH}${action.payload.id}`),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(getDeviceTimelineSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getDeviceTimelineFailure(err));
  }
}

export function* getDeviceUploadSampleCSVAPI() {
  while (true) {
    const action = yield take(`${getDeviceUploadSampleCSV}`);

    const { payload, err } = yield call(
      ApiInvoke,
      commonEndpoint(FILE_UPLOAD_INIT_PATH),
      {
        action: DOWNLOAD_ACTION,
        type: DEVICE_UPLOAD,
        file:
          action.payload.type === "action"
            ? ACTION_SAMPLE_CSV_NAME
            : CREATE_SAMPLE_CSV_NAME,
      },
      "POST"
    );

    if (payload.status === 200) {
      yield call(action.payload.callback, payload);
    }

    if (payload && !err) {
      yield put(getDeviceUploadSampleCSVSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getDeviceUploadSampleCSVFailure(err));
  }
}

export function* getTEEPropertiesAPI() {
  while (true) {
    const action = yield take(`${getTEEProperties}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(
        `${TEE_PROPERTIES_PATH}${action.payload.id}${TEE_PROPERTY}`
      ),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(getTEEPropertiesSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getTEEPropertiesFailure(err));
  }
}

export function* getDeviceTransactionLogsAPI() {
  while (true) {
    const action = yield take(`${getTransactionLogs}`);

    const { payload, err } = yield call(
      ApiInvoke,
      transactionBackendEndpoint(
        `${TRANSACTION_PATH}${action.payload.id}${TRANSACTION_PROPERTY}`
      ),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(getTransactionLogsSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getTransactionLogsFailure(err));
  }
}

export function* eligibilitySearchDeviceAPI() {
  while (true) {
    const action = yield take(`${eligibilitySearchDevice}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${ELIGIBILITY_SEARCH_DEVICE_PATH}`),
      action.payload.data,
      "POST"
    );

    if (payload && !err) {
      yield put(eligibilitySearchDeviceSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveDevicesFailure(err));
  }
}
