import {
  DashboardOutlined,
  ApiOutlined,
  FileProtectOutlined,
  LockOutlined,
  UserOutlined,
  TeamOutlined,
  FileTextOutlined,
  SafetyCertificateOutlined, 
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from './appConfig';


const dashBoardNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'Home',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'devices',
    path: `${APP_PREFIX_PATH}/devices`,
    title: 'Device/OEM Management',
    icon: ApiOutlined,
    breadcrumb: true,
    submenu: []
  },
  {
    key: 'crypto',
    path: `${APP_PREFIX_PATH}/crypto`,
    title: 'Binary Management',
    icon: LockOutlined,
    breadcrumb: true,
    submenu: []
  },
  {
    key: 'Certificates',
    path: `${APP_PREFIX_PATH}/certificates`,
    title: 'Certificate Management',
    icon: SafetyCertificateOutlined,
    breadcrumb: true,
    submenu: []
  },
  // {
  //   key: 'UserManagment',
  //   path: `${APP_PREFIX_PATH}/userManagement`,
  //   title: 'User Management',
  //   icon: UserOutlined,
  //   breadcrumb: true,
  //   submenu: []
  // },
  {
    key: 'policies',
    path: `${APP_PREFIX_PATH}/policies`,
    title: 'Eligibility Management',
    icon: FileProtectOutlined,
    breadcrumb: true,
    submenu: []
  },
  // {
  //   key: 'provisioning',
  //   path: `${APP_PREFIX_PATH}/provisioning`,
  //   title: 'Provisioning',
  //   icon: LinkOutlined,
  //   breadcrumb: true,
  //   submenu: []
  // },

  {
    key: 'users',
    // path: `${APP_PREFIX_PATH}/users`,
    title: 'User Management',
    icon: UserOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'users',
        path: `${APP_PREFIX_PATH}/users`,
        title: 'Users',
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'userGroups',
        path: `${APP_PREFIX_PATH}/userGroups`,
        title: 'Groups',
        icon: TeamOutlined,
        breadcrumb: true,
        submenu: []
      },
      {
        key: 'permissions',
        path: `${APP_PREFIX_PATH}/permissions`,
        title: 'Permissions',
        icon: FileTextOutlined,
        breadcrumb: true,
        submenu: []
      },
      // {
      //   key: 'CertificatesDetails',
      //   path: `${APP_PREFIX_PATH}/certificatesDetails`,
      //   title: 'Certificates Details',
      //   icon: SafetyCertificateOutlined,
      //   breadcrumb: true,
      //   submenu: []
      // }
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
