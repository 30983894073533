import Axios, { Method, AxiosInstance } from "axios";
import { getAccessToken } from "./checkAuth";

export const getUrl = (endPoint: string, reqType: string = "app"): string => {
  const url = "" + endPoint;
  return url;
};

export interface ResponseType {
  status: any;
  message: any;
}

export interface HeaderType {
  "Content-Type": any;
  Authorization: string;
}

export const getHeader = (): HeaderType => {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAccessToken()}`,
  };
};

const callApi = async <T>(
  url: string,
  params: T,
  method: Method = "GET",
  instance: AxiosInstance,
  resolve: any,
  reject: any
) => {
  instance
    .request({
      method,
      url,
      params: method === "GET" ? params : null,
      data: method === "GET" ? null : JSON.stringify(params),
    })
    .then((response) => {
      if (response.data) {
        resolve(response.data);
      } else {
        reject({
          status: 404,
          message: "Record Not Found!",
        });
      }
    })
    .catch((error) => {
      reject(error);
    });
};

const ApiInvoke = async (
  endPoint: string,
  params: any,
  method: Method = "GET"
) => {
  const url = "" + endPoint;
  const instance = Axios.create({
    timeout: 100000,
    headers: getHeader(),
  });

  instance.interceptors.response.use((response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else if (response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  });

  return new Promise((resolve, reject) => {
    callApi(url, params, method, instance, resolve, reject);
  })
    .then((payload) => ({ payload }))
    .catch((err) => ({ err }));
};

export default ApiInvoke;
