import { UserGroupState,} from 'types/user';
import {
  RETRIEVE_USEGROUPS,
  RETRIEVE_USEGROUPS_SUCCESS,
  RETRIEVE_USEGROUPS_FAILIURE,
  CREATE_USER_GROUP,
  CREATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP,
  UPDATE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP,
  // DELETE_USER_GROUP_SUCCESS

} from '../constants/UserGroups';

const initialState: UserGroupState = {
  loading: false,
  error: false,
  total: 0,
  message: '',
  userGroup: [],
};

function userGroupReducer(state = initialState, action) {
  const { type, payload } = action;
  console.debug(action);

  switch (type) {

    case CREATE_USER_GROUP:
      return {
        ...state,
        loading: true,
        message: payload.data.message
      };
    case CREATE_USER_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.data.message
      };

    case RETRIEVE_USEGROUPS:

      return { ...state, loading: true };


    case RETRIEVE_USEGROUPS_SUCCESS:

      return { ...state, loading: false, groups: payload.data.groups };


    case RETRIEVE_USEGROUPS_FAILIURE:
      return { ...state, loading: false, error: true, message: payload.data.message || '' };

    default:
      return state;

    case UPDATE_USER_GROUP:

      return {
        ...state,
        loading: true,
        groups: state.userGroup.map((groups: any) => {

          if (groups._id === payload.data._id) {
            return {
              ...groups,
              ...payload.data

            };

          } else {

            return groups;
          }

        })
      };

    case UPDATE_USER_GROUP_SUCCESS:
      return { ...state, loading: false, message: payload.message };


    case DELETE_USER_GROUP:
      return { ...state, loading: true };

  }
}

export default userGroupReducer;
