import { take, call, put } from "redux-saga/effects";
import {
  createEligibility,
  createEligibilitySuccess,
  deleteEligibilityRules,
  deleteEligibilitySuccess,
  retrieveEligibility,
  retrieveEligibilityRule,
  retrieveEligibilityRulesSuccess,
  retrieveEligibilityFailure,
  retrieveEligibilitySuccess,
  searchEligibility,
  searchEligibilitySuccess,
  updateEligibility,
  updateEligibilitySuccess,
  deleteEligibilityFailure,
  getEligibilityStats,
  getEligibilityStatsSuccess,
  getEligibilityStatsFailure,
  changeEligibilityStatus,
  changeEligibilityStatusSuccess,
  changeEligibilityStatusFailure,
} from "../actions/Eligibility";
import {
  CREATE_ELIGIBILITY_RULE_PATH,
  DELETE_ELIGIBILITY_RULES_PATH,
  RETRIEVE_ELIGIBILITY_RULES_PATH,
  UPDATE_ELIGIBILITY_RULE_PATH,
  SEARCH_ELIGIBILITY_RULE_PATH,
  RETRIEVE_ELIGIBILITY_RULE_PATH,
  GET_ELIGIBILITY_STATS_PATH,
  CHANGE_ELIGIBILITY_STATUS_PATH,
} from "../constants/Eligibility";
import { endSession } from "../../utils/commonUtils";
import ApiInvoke from "utils/apiFetch";

const backendEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_ELIGIBILITY_BACKEND_DEV}/${path}`
    : `${process.env.REACT_APP_ELIGIBILITY_BACKEND_QA}/${path}`;

export function* retrieveEligibilityRulesAPI() {
  while (true) {
    yield take(`${retrieveEligibility}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(RETRIEVE_ELIGIBILITY_RULES_PATH),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(retrieveEligibilitySuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveEligibilityFailure(err));
  }
}

export function* retrieveEligibilityRuleAPI() {
  while (true) {
    const action = yield take(`${retrieveEligibilityRule}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${RETRIEVE_ELIGIBILITY_RULE_PATH}${action.payload.id}`),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(retrieveEligibilityRulesSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveEligibilityFailure(err));
  }
}

export function* createEligibilityRuleAPI() {
  while (true) {
    const action = yield take(`${createEligibility}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(CREATE_ELIGIBILITY_RULE_PATH),
      action.payload.data,
      "POST"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(createEligibilitySuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveEligibilityFailure(err));
  }
}

export function* updateEligibilityRuleAPI() {
  while (true) {
    const action = yield take(`${updateEligibility}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${UPDATE_ELIGIBILITY_RULE_PATH}${action.payload.id}`),
      action.payload.data,
      "PUT"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(updateEligibilitySuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveEligibilityFailure(err));
  }
}

export function* deleteEligibilityRulesAPI() {
  while (true) {
    const action = yield take(`${deleteEligibilityRules}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(DELETE_ELIGIBILITY_RULES_PATH),
      action.payload.data,
      "POST"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(deleteEligibilitySuccess(payload));
      yield put(retrieveEligibility({}));
      continue;
    }
    endSession(err);
    yield put(deleteEligibilityFailure(err));
  }
}

export function* searchEligibilityAPI() {
  while (true) {
    const action = yield take(`${searchEligibility}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${SEARCH_ELIGIBILITY_RULE_PATH}${action.payload.path}`),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(searchEligibilitySuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveEligibilityFailure(err));
  }
}

export function* getEligibilityStatsAPI() {
  while (true) {
    yield take(`${getEligibilityStats}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(GET_ELIGIBILITY_STATS_PATH),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(getEligibilityStatsSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getEligibilityStatsFailure(err));
  }
}

export function* changeEligibilityStatusAPI() {
  while (true) {
    const action = yield take(`${changeEligibilityStatus}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${CHANGE_ELIGIBILITY_STATUS_PATH}${action.payload.id}`),
      action.payload.data,
      "PUT"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(changeEligibilityStatusSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(changeEligibilityStatusFailure(err));
  }
}
