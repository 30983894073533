import {
  CREATE_CERTIFICATE,
  CREATE_CERTIFICATE_SUCCESS,
  RETRIEVE_CERTIFICATES,
  RETRIEVE_CERTIFICATES_SUCCESS,
  RETRIEVE_CERTIFICATES_FAILIURE,
  RETRIEVE_CERTIFICATE,
  RETRIEVE_CERTIFICATE_SUCCESS,
  RETRIEVE_CERTIFICATE_FAILIURE,
  RETRIEVE_CERTIFICATE_STATUS,
  RETRIEVE_CERTIFICATE_STATUS_SUCCESS,
  RETRIEVE_CERTIFICATE_STATUS_FAILIURE,
  SEARCH_CERTIFICATE,
  SEARCH_CERTIFICATE_SUCCESS,
} from "../constants/Certificate";

const initialState = {
  loading: false,
  error: false,
  total: 0,
  message: "",
  certificates: [],
  certificate: {},
  certificateStatus: {},
};

function certificateReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CERTIFICATE:
      return { ...state, loading: true };

    case CREATE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        certificates: [...state.certificates, payload.data],
        message: payload.message,
      };

    case RETRIEVE_CERTIFICATES:
      return { ...state, loading: true };

    case RETRIEVE_CERTIFICATES_SUCCESS:
      return {
        ...state,
        loading: false,
        certificates: payload.data.certificates,
      };

    case RETRIEVE_CERTIFICATES_FAILIURE:
      return {
        ...state,
        loading: false,
        error: true,
        message: payload.message || "",
      };

    case RETRIEVE_CERTIFICATE:
      return { ...state, loading: true };

    case RETRIEVE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        certificate: payload.data.certificate,
      };

    case RETRIEVE_CERTIFICATE_FAILIURE:
      return {
        ...state,
        loading: false,
        error: true,
        message: payload.message || "",
      };

    case RETRIEVE_CERTIFICATE_STATUS:
      return { ...state, loading: true };

    case RETRIEVE_CERTIFICATE_STATUS_SUCCESS:
      return { ...state, loading: false, certificateStatus: payload.data };

    case RETRIEVE_CERTIFICATE_STATUS_FAILIURE:
      return {
        ...state,
        loading: false,
        error: true,
        message: payload.message || "",
      };

    case SEARCH_CERTIFICATE:
      return { ...state, loading: true };

    case SEARCH_CERTIFICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        certificates: payload.data.certificates,
      };

    default:
      return state;
  }
}

export default certificateReducer;
