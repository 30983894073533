import {
  SEARCH_USERS,
  SEARCH_USERS_SUCCESS,
  // GET_USERS,
  // GET_USERS_SUCCESS,
  // GET_USERS_FAILURE,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILIURE,
  RETRIEVE_USERS,
  RETRIEVE_USERS_SUCCESS,
  RETRIEVE_USERS_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
} from '../constants/Users';

import { createAction } from 'redux-act';

export const createUser = createAction<any | undefined>(CREATE_USER);
export const createUserSucces = createAction<any | undefined>(CREATE_USER_SUCCESS);
export const retrieveUsers = createAction<any | undefined> (RETRIEVE_USERS);
export const retrieveUsersSucces = createAction<any | undefined> (RETRIEVE_USERS_SUCCESS);
export const retrieveUsersFailure = createAction<any | undefined >(RETRIEVE_USERS_FAILURE);
export const deleteUser = createAction<any | undefined>(DELETE_USER);
export const deleteUserSuccess = createAction<any | undefined>(DELETE_USER_SUCCESS);
export const deleteUserFailure = createAction<any | undefined>(DELETE_USER_FAILIURE);
export const searchUsers = createAction<any | undefined>(SEARCH_USERS);
export const searchUsersSuccess = createAction<any | undefined>(SEARCH_USERS_SUCCESS);
export const updateUser = createAction<any|undefined>(UPDATE_USER);
export const updateUserSuccess = createAction<any|undefined>(UPDATE_USER_SUCCESS);



// export const getUsers = createAction<any | undefined>(GET_USERS);
// export const getUsersSuccess = createAction<any | undefined>(GET_USERS_SUCCESS);
// export const getUsersFailure = createAction<any | undefined>(GET_USERS_FAILURE);
