import {
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  RETRIEVE_CATEGORIES,
  RETRIEVE_CATEGORIES_FAIL,
  RETRIEVE_CATEGORIES_SUCCESS,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS
} from '../constants/Category';

import { createAction } from 'redux-act';

// Category
export const createCategory = createAction<any | undefined>(CREATE_CATEGORY);
export const createCategorySuccess = createAction<any | undefined>(CREATE_CATEGORY_SUCCESS);
export const updateCategory = createAction<any | undefined>(UPDATE_CATEGORY);
export const updateCategorySuccess = createAction<any | undefined>(UPDATE_CATEGORY_SUCCESS);
export const deleteCategory = createAction<any | undefined>(DELETE_CATEGORY);
export const deleteCategorySuccess = createAction<any | undefined>(DELETE_CATEGORY_SUCCESS);

export const retrieveCategories = createAction<any | undefined>(RETRIEVE_CATEGORIES);
export const retrieveCategoriesSuccess = createAction<any | undefined>(RETRIEVE_CATEGORIES_SUCCESS);
export const retrieveCategoriesFailure = createAction<any | undefined>(RETRIEVE_CATEGORIES_FAIL);
