import {
  CREATE_CRYPTO_ASSET,
  CREATE_CRYPTO_ASSET_SUCCESS,
  DELETE_CRYPTO_ASSET,
  DELETE_CRYPTO_ASSETS,
  DELETE_CRYPTO_ASSET_SUCCESS,
  RETRIEVE_CRYPTO_ASSETS,
  RETRIEVE_CRYPTO_ASSETS_FAIL,
  RETRIEVE_CRYPTO_ASSETS_SUCCESS,
  SEARCH_CRYPTO_ASSET,
  SEARCH_CRYPTO_ASSET_SUCCESS,
  UPDATE_CRYPTO_ASSET,
  UPDATE_CRYPTO_ASSET_SUCCESS,
  FILE_UPLOAD_INIT,
  RETRIEVE_CRYPTO_ASSET,
  RETRIEVE_CRYPTO_ASSET_SUCCESS,
  GET_CRYPTO_STATS,
  GET_CRYPTO_STATS_SUCCESS,
  GET_CRYPTO_STATS_FAILURE,
  DOWNLOAD_CRYPTO_ASSET,
  GET_AUDIT_LOGS,
  GET_AUDIT_LOGS_SUCCESS,
  GET_AUDIT_LOGS_FAILURE,
  RETRIEVE_SERVICE_PROVIDERS,
  RETRIEVE_SERVICE_PROVIDERS_SUCCESS,
  RETRIEVE_SERVICE_PROVIDERS_FAILURE,
  CRYPTO_BULK_UPLOAD,
  GET_CRYPTO_UPLOAD_SAMPLE_CSV,
  GET_CRYPTO_UPLOAD_SAMPLE_CSV_SUCCESS,
  GET_CRYPTO_UPLOAD_SAMPLE_CSV_FAILURE,
  CRYPTO_BINARY_UPLOAD_SIGNED,
  CRYPTO_BULK_UPLOAD_SUCCESS,
  CRYPTO_BULK_UPLOAD_FAILURE,
  ELIGIBILITY_SEARCH_CRYPTO_ASSET,
  ELIGIBILITY_SEARCH_CRYPTO_ASSET_SUCCESS,
} from '../constants/Crypto';

import { createAction } from 'redux-act';

// Crypto
export const createCrypto = createAction<any | undefined>(CREATE_CRYPTO_ASSET);
export const createCryptoSuccess = createAction<any | undefined>(CREATE_CRYPTO_ASSET_SUCCESS);
export const updateCrypto = createAction<any | undefined>(UPDATE_CRYPTO_ASSET);
export const updateCryptoSuccess = createAction<any | undefined>(UPDATE_CRYPTO_ASSET_SUCCESS);
export const deleteCrypto = createAction<any | undefined>(DELETE_CRYPTO_ASSET);
export const deleteCryptoAssets = createAction<any | undefined>(DELETE_CRYPTO_ASSETS);
export const deleteCryptoSuccess = createAction<any | undefined>(DELETE_CRYPTO_ASSET_SUCCESS);
export const cryptoBulkUpload = createAction<any | undefined>(CRYPTO_BULK_UPLOAD);
export const cryptoBulkUploadSuccess = createAction<any | undefined>(CRYPTO_BULK_UPLOAD_SUCCESS);
export const cryptoBulkUploadFailure = createAction<any | undefined>(CRYPTO_BULK_UPLOAD_FAILURE);

export const retrieveCrypto = createAction<any | undefined>(RETRIEVE_CRYPTO_ASSETS);
export const retrieveCryptoSuccess = createAction<any | undefined>(RETRIEVE_CRYPTO_ASSETS_SUCCESS);
export const retrieveCryptoAsset = createAction<any | undefined>(RETRIEVE_CRYPTO_ASSET);
export const retrieveCryptoAssetSuccess = createAction<any | undefined>(RETRIEVE_CRYPTO_ASSET_SUCCESS);
export const retrieveCryptoFailure = createAction<any | undefined>(RETRIEVE_CRYPTO_ASSETS_FAIL);

export const retrieveServiceProviders = createAction<any | undefined>(RETRIEVE_SERVICE_PROVIDERS);
export const retrieveServiceProvidersSuccess = createAction<any | undefined>(RETRIEVE_SERVICE_PROVIDERS_SUCCESS);
export const retrieveServiceProvidersFailure = createAction<any | undefined>(RETRIEVE_SERVICE_PROVIDERS_FAILURE);

export const getCryptoUploadSampleCSV = createAction<any | undefined>(GET_CRYPTO_UPLOAD_SAMPLE_CSV);
export const getCryptoUploadSampleCSVSuccess = createAction<any | undefined>(GET_CRYPTO_UPLOAD_SAMPLE_CSV_SUCCESS);
export const getCryptoUploadSampleCSVFailure = createAction<any | undefined>(GET_CRYPTO_UPLOAD_SAMPLE_CSV_FAILURE);

export const searchCrypto = createAction<any | undefined>(SEARCH_CRYPTO_ASSET);
export const searchCryptoSuccess = createAction<any | undefined>(SEARCH_CRYPTO_ASSET_SUCCESS);

export const fileUploadInit = createAction<any | undefined>(FILE_UPLOAD_INIT);
export const downloadCryptoAsset = createAction<any | undefined>(DOWNLOAD_CRYPTO_ASSET);

export const getCryptoStats = createAction<any | undefined>(GET_CRYPTO_STATS);
export const getCryptoStatsSuccess = createAction<any | undefined>(GET_CRYPTO_STATS_SUCCESS);
export const getCryptoStatsFailure = createAction<any | undefined>(GET_CRYPTO_STATS_FAILURE);

export const getAuditLogs = createAction<any | undefined>(GET_AUDIT_LOGS);
export const getAuditLogsSuccess = createAction<any | undefined>(GET_AUDIT_LOGS_SUCCESS);
export const getAuditLogsFailure = createAction<any | undefined>(GET_AUDIT_LOGS_FAILURE);

export const downloadSignedBinary = createAction<any | undefined>(CRYPTO_BINARY_UPLOAD_SIGNED);

export const eligibilitySearchCrypto = createAction<any | undefined>(ELIGIBILITY_SEARCH_CRYPTO_ASSET);
export const eligibilitySearchCryptoSuccess = createAction<any | undefined>(ELIGIBILITY_SEARCH_CRYPTO_ASSET_SUCCESS);