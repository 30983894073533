import React, { lazy, Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import Loading from 'components/common/Loading';
import { APP_PREFIX_PATH } from 'configs/appConfig';

export const AppViews = () => {
  const history = useHistory();
  const authData = useSelector((state: { auth: any }) => state.auth);

  useEffect(() => {
    if (authData.isPrepared && !authData.isLoggedIn) {
      history.push('/auth');
    }
  }, [authData, history]);

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/devices/:id`} component={lazy(() => import(`./devices/deviceDetails`))} />
        <Route path={`${APP_PREFIX_PATH}/devices`} component={lazy(() => import(`./devices`))} />
        <Route path={`${APP_PREFIX_PATH}/crypto/:id`} component={lazy(() => import(`./crypto/cryptoDetails`))} />
        <Route path={`${APP_PREFIX_PATH}/crypto`} component={lazy(() => import(`./crypto`))} />
        <Route
          path={`${APP_PREFIX_PATH}/policies/create`}
          component={lazy(() => import(`./eligibility/components/createPolicies`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/policies/update/:id`}
          component={lazy(() => import(`./eligibility/components/createPolicies`))}
        />
        <Route path={`${APP_PREFIX_PATH}/policies/:id`} component={lazy(() => import(`./eligibility/policyDetails`))} />
        <Route path={`${APP_PREFIX_PATH}/policies`} component={lazy(() => import(`./eligibility`))} />
        <Route path={`${APP_PREFIX_PATH}/provisioning`} component={lazy(() => import(`./provisioning`))} />
        <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users/MainView`))} />
        <Route path={`${APP_PREFIX_PATH}/userGroups`} component={lazy(() => import(`./userGroups/userGroups`))} />
        <Route path={`${APP_PREFIX_PATH}/permissions`} component={lazy(() => import(`./permissions/permissions`))} />
        <Route path={`${APP_PREFIX_PATH}/certificates/:id`} component={lazy(() => import(`./certificates/certificatesDetails`))} />
        <Route path={`${APP_PREFIX_PATH}/certificates`} component={lazy(() => import(`./certificates`))} />
        <Route path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./profile/profile`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
