// import { TPermission, UserPermissionState } from 'types/permission';
import {
  RETRIEVE_PERMISSIONS,
  RETRIEVE_PERMISSIONS_SUCCESS,
  RETRIEVE_PERMISSIONS_FAILIURE,
  RETRIEVE_USER_PERMISSIONS,
  RETRIEVE_USER_PERMISSIONS_SUCCESS,
  RETRIEVE_USER_PERMISSIONS_FAILIURE,
  CREATE_PERMISSIONS,
  CREATE_PERMISSIONS_SUCCESS,
  // CREATE_PERMISSIONS_FAILIURE,
  UPDATE_PERMISSIONS,
  UPDATE_PERMISSIONS_SUCCESS,
  // UPDATE_PERMISSIONS_FAILIURE,
  DELETE_PERMISSIONS,
  DELETE_PERMISSIONS_SUCCESS,
} from "../constants/Permissions";

const initialState = {
  permissionsLoading: false,
  error: false,
  total: 0,
  message: "",
  permissions: [],
  user_permissions: [],
};

function userPermissonReducer(state = initialState, action) {
  const { type, payload } = action;
  console.debug(action);

  switch (type) {
    case RETRIEVE_PERMISSIONS:
      return { ...state, permissionsLoading: true };

    case RETRIEVE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsLoading: false,
        permissions: payload.data.permissions ?? [],
      };

    case RETRIEVE_PERMISSIONS_FAILIURE:
      return {
        ...state,
        permissionsLoading: false,
        error: true,
        message: payload.data.message || "",
      };

    case RETRIEVE_USER_PERMISSIONS:
      return { ...state, permissionsLoading: true };

    case RETRIEVE_USER_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsLoading: false,
        user_permissions: payload.data.permissions ?? [],
      };

    case RETRIEVE_USER_PERMISSIONS_FAILIURE:
      return {
        ...state,
        permissionsLoading: false,
        error: true,
        message: payload.data.message || "",
      };

    case CREATE_PERMISSIONS:
      return {
        ...state,
        permissionsLoading: true,
        message: payload.data.message,
      };
    case CREATE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsLoading: false,
        message: payload.data.message,
      };

    case UPDATE_PERMISSIONS:
      return {
        ...state,
        permissionsLoading: true,
        permissions: state.permissions.map((permission: any) => {
          if (permission._id === payload.data._id) {
            console.log("EEEEEEEEEEEEE ", payload);
            return {
              ...permission,
              ...payload.data,
            };
          } else {
            return permission;
          }
        }),
      };

    case UPDATE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsLoading: false,
        message: payload.data.message,
      };

    case DELETE_PERMISSIONS:
      return {
        ...state,
        permissionsLoading: true,
        rules: state.permissions.map((permission: any, index) => {
          console.log(permission._id, payload.id);
          if (permission._id === payload.id) {
            return {
              ...state.permissions.splice(index, 1),
            };
          } else {
            return permission;
          }
        }),
      };

    case DELETE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsLoading: false,
        permissions: payload.data.users,
      };

    default:
      return state;
  }
}

export default userPermissonReducer;
